<template>
  <div class="pa-sm-0 text-center">
    <h1 class="justify-center mx-auto px-sm-0">
      {{ $t('product-finder.website-retry.title') }}
    </h1>
    <div class="my-4">
      {{ $t('product-finder.website-retry.sub-title') }}
    </div>

    <div class="my-12">
      <h4 class="pb-2 text-left">
        {{ $t('product-finder.website-retry.link-label') }}
      </h4>
      <v-form ref="website">
        <v-text-field
          :value="websiteUrl"
          class="website-input"
          outlined
          type="url"
          :placeholder="$t('product-finder.enter-website-placeholder')"
          :rules="[rules.url, rules.required]"
          validate-on-blur
          @keydown.enter.prevent="proceed"
          @input="$emit('websiteUrl', $event)"
        />
      </v-form>
    </div>

    <div class="d-flex mt-12 gap-1">
      <Button @click="$emit('back')">
        {{ $t('buttons.product-finder.back') }}
      </Button>
      <Button
        primary
        @click="$emit('next')"
      >
        {{ $t('buttons.product-finder.retry') }}
      </Button>
    </div>
  </div>
</template>

<script>
import countries from '@/mixins/countries'
import { url, required } from '@/lib/validation'
import Button from './Button.vue'

export default {
  components: { Button },
  mixins: [countries],
  props: {
    websiteUrl: {
      type: String,
      default: ''
    }
  },
  created () {
    this.rules = { url, required }
  },
  methods: {
    proceed () {
      const valid = this.$refs.website.validate()
      if (valid) {
        this.$emit('next')
      }
    }
  }
}
</script>
